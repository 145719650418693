import { Spin } from 'antd';

export const Show = ({children, isLoading}:any) => {
  return isLoading ? (
    <div
      style={{
        marginTop: '20%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Spin size="large" />
    </div>
  ) : (
    children
  );
};