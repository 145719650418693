import React, { ChangeEvent, useState } from 'react';
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { apiUrl } from '../../constants';
import logo from "../../assets/img/logo.png";
import loginCover from "../../assets/img/login_cover.jpg";
import { Button, Spin } from 'antd';

export const Signup: React.FC = () => {
    const navigate = useNavigate();
    const [age, setAge] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("User");
    const [image, setImage] = useState<any>();
    const [password, setPassword] = useState("");
    const [userName, setUserName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [errorMessage, setErrorMessage] = useState("");


    const uploadImage = async (formData: FormData): Promise<string> => {
        const response = await fetch(`${apiUrl}/image-upload`, {
            method: 'POST',
            body: formData,
        });
        const data = await response.json();
        return data.imageUrl;
    };


    const { mutate, isLoading } = useMutation<any>(
        (param: any) =>
            fetch(`${apiUrl}/auth/signup`, {
                method: "POST",
                body: JSON.stringify(param),
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            }),
        {
            onSuccess: async (data) => {
                if (data?.status != 200) {
                    setErrorMessage("Couldn't signup, Please retry!!!");
                } else {
                    navigate("/login")
                }

            },
            onError: (error) => {
                setErrorMessage("Couldn't signup, Please retry!!!");
            }
        }
    );

    const onFinish = async (event: any) => {
        event.preventDefault();
        if (!userName || !age || !name || !phoneNumber || !email || !image || !password) {
            setErrorMessage("Please fill all the fields")
        } else {
            const formData = new FormData();
            formData.append("image", image);
            const imageUrl = await uploadImage(formData);
            handleMutate(imageUrl);
        }
    };

    const handleMutate = async (imageUrl: string) => {
        // @ts-ignore
        await mutate({
            username: userName, age: age, name: name, phoneNumber: phoneNumber, email: email, role: "User", image: imageUrl, password: password
        });
    };

    const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement
        if (target.files) {
            setImage(target.files[0]);
        }
    }

    return (
        <section className="bg-gray-50 dark:bg-gray-900" style={{
            backgroundImage: `url(${loginCover})`,
            backgroundSize: "cover",
        }}>
            <div className="flex flex-col items-center justify-center  md:h-screen lg:py-0">


                <div className="bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-full w-[80vw] xl:p-0 dark:bg-gray-800 dark:border-gray-700 ">
                    <a href="#" className="flex ml-[35vw] mt-[0.3rem] mb-[-2rem] items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                        <img className="w-24 h-24 mr-2" src={logo} alt="logo" />
                    </a>
                    <div className="p-6 w-[90vw] space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                            Sign up
                        </h1>
                        <div className="text-red-600">{errorMessage}</div>   
                        {isLoading ? <Spin className='ml-[41%] mt-[-5%]'/> : ""}                       
                        <form className="space-y-4 md:space-y-6" action="#" encType="multipart/form-data">
                            <div className='flex w-full'>
                                <div className='w-[40%] mr-20'>
                                    <div>
                                        <label htmlFor="name" className="block mt-2 mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Full Name</label>
                                        <input onChange={(event) => setName(event.target.value)} type="text" name="name" value={name} id="name" className="bg-gray-50 mr-5 w-80 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Abebe Kebede" required />
                                    </div>
                                    <div>
                                        <label htmlFor="useName" className="block mt-2 mb-2 text-sm font-medium text-gray-900 dark:text-white">User Name</label>
                                        <input onChange={(event) => setUserName(event.target.value)} type="text" name="userName" id="userName" value={userName} className="bg-gray-50 mr-5 w-80  border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="user name" required />
                                    </div>
                                    <div>
                                        <label htmlFor="age" className="block mt-2 mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Age</label>
                                        <input onChange={(event) => setAge(event.target.value)} type="text" name="age" value={age} id="age" className="bg-gray-50 mr-5 w-80  border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="50" required />
                                    </div>
                                    <div>
                                        <label htmlFor="phoneNumber" className="block mt-2 mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Phone number</label>
                                        <input onChange={(event) => setPhoneNumber(event.target.value)} type="text" name="phoneNumber" value={phoneNumber} id="phoneNumber" className="bg-gray-50 w-80 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="09...." required />
                                    </div>

                                </div>
                                <div className='w-[40%]'>
                                    <div>
                                        <label htmlFor="email" className="block mt-2 mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Email</label>
                                        <input onChange={(event) => setEmail(event.target.value)} type="email" name="email" value={email} id="email" className="bg-gray-50 border w-80 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required />
                                    </div>
                                    <div>
                                        <label htmlFor="password" className="block mt-2 mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                        <input onChange={(event) => setPassword(event.target.value)} type="password" name="password" value={password} id="password" placeholder="••••••••" className="bg-gray-50 w-80 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                    </div>
                                    <div className='flex mt-4'>
                                        <label htmlFor="image" className="block mt-2 mr-2 mb-2 text-sm font-medium text-gray-900 dark:text-white">Upload Your Image: </label>
                                        <input onChange={handleImageChange} type="file" name="image" id="image" accept="image/*" className="hidden" />
                                        <label htmlFor="image" className="flex flex-col items-center justify-center w-80 h-36 rounded-lg bg-gray-100 border border-gray-300 cursor-pointer hover:bg-gray-200 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-600 dark:bg-gray-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:focus-within:ring-blue-500">
                                            {image ? (
                                                <img src={URL.createObjectURL(image)} alt="Selected Image" className="w-80 h-28 object-contain rounded-lg" />
                                            ) : (
                                                <span className="w-40 h-40 text-gray-400 flex items-center justify-center">Select an Image</span>
                                            )}
                                        </label>

                                    </div>
                                </div>

                            </div>


                            <Button onClick={onFinish} type="primary" className="w-1/2 ml-[20%]">Sign up</Button>
                            <p className="text-sm font-light text-gray-500  ml-[35%] mt-[-1rem] dark:text-gray-400">
                                Do you have an account? <a onClick={() => navigate("/login")} className="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign in</a>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};
