import React, { useState } from 'react';
import jwt_decode from "jwt-decode";
import {
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Dashboard, Login, Menu, Place } from './pages';
import { Signup } from './pages/SignUp/Signup';
import { PlaceList } from './pages/PlaceList';

const queryClient = new QueryClient();

export const PrivateRoute = ({ children }: any) => {
  console.log("🚀 ~ file: App.tsx:6 ~ PrivateRoute ~ children:", children)
  //const { isLoggedIn } = React.useContext()
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  let userdata = localStorage.getItem('userdata');
  console.log(userdata);
  console.log("🚀 ~ PrivateRoute ~ isLoggedIn:", userdata ? '11' : '22');
  //return isLoggedIn?children : <Navigate to="/login" />
  return userdata ? children : <Navigate to="/login" />
}

const App = () => {
  console.log('------------------');
  
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          {/* <Route
            path='/dashboard'
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          /> */}
          <Route
            path='/'
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route path="/placeList" element={<PlaceList />} />
          <Route path="/login" element={<Login />} />
          <Route path='/signup' element={<Signup />} />
          <Route path="/place" element={<Place />} />
          {/* <Route path="/" element={<Menu />} /> */}
          {/* <Route path="/" element={<Dashboard />} /> */}
        </Routes>

      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;