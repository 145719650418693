import _ from "lodash";
import axios from "axios";
import { apiUrl } from "../../constants";
import { useLocation } from "react-router-dom";
import { IPlaceCategories } from "../../interfaces";
import { useEffect, useRef, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { MenuCategories, Show } from "../../components";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Space, Row, Col, Modal, Button, Divider, Tabs, List, Input } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';


const { confirm } = Modal;
const { TextArea } = Input;

export const Place: React.FC = () => {
  // state variables
  const location = useLocation();
  const [menus, setMenus] = useState<any>({});
  const [open, setOpen] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const nameRef = useRef<any>('');
  const priceRef = useRef<any>(0);
  const descriptionRef = useRef<any>('');
  const [menuName, setMenuName] = useState('');
  const [menuPrice, setMenuPrice] = useState(0);
  const [menuDescription, setMenuDescription] = useState('');
  const [selectedMenu, setSelectedMenu] = useState<any>({});
  const [activeTab, setActiveTab] = useState('1'); // default active tab
  const [editCat, setEditCat] = useState("hidden")

  // API calls
  const config = { headers: { "Content-Type": "application/json" }, withCredentials: true }
  const fetchPlaces = async () => {
    const data = await axios.get(`${apiUrl}/place/${location.state.id}`, config)
    return data;
  };
  const { mutate: updateMenu, isLoading: isMenuLoading } = useMutation((DATA: any) => {
    return axios.post(`${apiUrl}/place/${redordId}`, DATA);
  });
  const { isSuccess, isLoading, isFetching, error, data } = useQuery(['place'], fetchPlaces);
  const record = data?.data;
  const redordId = record?._id;

  // submit and edit handlers
  useEffect(() => {
    if (isSuccess && data && data.data && data.data.menus && data.data.menus[0]) {
      setMenus(data.data.menus[0]);
    }
  }, [data, isSuccess]);

  const handleTabChange = (key: any) => {
    setActiveTab(key);
  };

  const renderMenus = (item: any) => {
    const showEditModal = (item: any) => {
      setMenuName(item?.name ? item?.name : '');
      setMenuPrice(item?.price ? item?.price : 0);
      setMenuDescription(item?.description ? item?.description : '');
      setSelectedMenu(item);
      setOpen(true);
    }

    const handleEdit = (e: React.MouseEvent<HTMLElement>) => {
      const dataToEdit = { name: menuName, price: menuPrice, description: menuDescription };
      let menusCopy = menus;
      let tempArr = menus[item?.key];
      const edited = tempArr.map((m: any) => m?.name === selectedMenu?.name ? dataToEdit : m);
      menusCopy[item?.key] = edited;
      updateMenu({
        menus: [menusCopy],
      });
      setOpen(false);
    };

    const handleAdd = () => {
      const dataToAdd: any = { name: nameRef.current, price: parseInt(priceRef.current), description: descriptionRef.current };
      let menusCopy = menus;
      let tempArr = menus[item?.key];
      tempArr.push(dataToAdd);
      menusCopy[item?.key] = tempArr;
      console.log("🚀 ~ file: show.tsx:69 ~ handleAdd ~ tempArr:", JSON.stringify(menusCopy))
      updateMenu({
        menus: [menusCopy],
      });
      setShowAddModal(false);
    };


    const handleDelete = (val: string) => {
      let menusCopy = menus;
      let tempArr = menus[item?.key];
      const edited = tempArr.filter((m: any) => m?.name !== val);
      menusCopy[item?.key] = edited;
      updateMenu({
        menus: [menusCopy],
      });

    }

    const showDeleteConfirm = (val: string) => {
      confirm({
        title: `Are you sure delete ${val} ?`,
        icon: <ExclamationCircleFilled />,
        content: 'Some descriptions',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          handleDelete(val)
        },
        onCancel() { },
      });
    };

    const showSelectConfirm = () => {
      confirm({
        title: `Please select a category first!`,
        icon: <ExclamationCircleFilled />,
        content: 'You need to create or select an existing category first.',
        cancelText: 'No',
        onCancel() { },
      });
    };


    return (
      <>

        <div className="w-[40%]">
          <List
            className="demo-loadmore-list"
            itemLayout="horizontal"
            dataSource={menus[item.key]}
            renderItem={(item: any) => (
              <List.Item

                actions={[
                  <Space key={item?.name}>

                    <Button
                      type="text"
                      icon={<EditOutlined />}
                      onClick={() => {
                        showEditModal(item);
                      }}
                    />
                    <Button danger
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        showDeleteConfirm(item?.name)
                      }}
                    />
                  </Space>
                ]}
              >
                <List.Item.Meta
                  title={<a href="https://ant.design">{item.name}</a>}
                  description={item?.description}
                />
                <div>{item?.price} Birr</div>
              </List.Item>
            )}
          />
        </div>


        <Modal
          title="Edit Menu"
          open={open}
          onOk={handleEdit}
          onCancel={() => setOpen(false)}
        >
          <div className="flex flex-col pb-5 pt-1">
            <p className="p-5">Name</p>
            <Input value={menuName} onChange={(e) => setMenuName(e.target.value)} placeholder="Basic usage" className="w-full" />
          </div>
          <div className="flex flex-col pb-5 pt-1">
            <p className="p-5">Price</p>
            <Input value={menuPrice} onChange={(e) => setMenuPrice(parseInt(e.target.value))} type="number" suffix="ETB" className="w-full" />
          </div>
          <p className="pt-5">Menu Description</p>
          <TextArea value={menuDescription} onChange={(e) => setMenuDescription(e.target.value)} rows={4} placeholder="maxLength is 30" maxLength={30} className="w-full" />
        </Modal>

        {/** Add new  */}
        <Modal
          title="Add Menu"
          open={showAddModal}
          onOk={handleAdd}
          onCancel={() => setShowAddModal(false)}
        >
          <div className="flex flex-col pb-5 pt-1">
            <p className="p-5">Name</p>
            <Input onChange={(e) => { nameRef.current = e.target.value }} placeholder="Basic usage" className="w-full" />
          </div>
          <div className="flex flex-col pb-5 pt-1">
            <p className="p-5">Price</p>
            <Input onChange={(e) => { priceRef.current = e.target.value }} type="number" suffix="ETB" className="w-full" />
          </div>
          <p className="pt-5">Menu Description</p>
          <TextArea onChange={(e) => { descriptionRef.current = e.target.value }} rows={4} placeholder="maxLength is 30" maxLength={30} className="w-full" />
        </Modal>


      </>
    );
  };

  return (
    <>
      <Navbar onOpenSidenav={function (): void {
        throw new Error("Function not implemented.");
      }} brandText={""} />
      <a href="/placeList" className="fixed top-40 left-[-2.2rem] z-10 bg-gray-200 hover:bg-gray-300 text-gray-700 font-semibold py-1 px-4 border border-gray-400 rounded shadow transform rotate-90">
        Place List
      </a>
      <Show isLoading={isLoading}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="center" style={{ margin: '20px 10px' }}>
          <a href="#" className="flex flex-col w-1/2 items-center bg-white border border-gray-200 rounded-lg shadow-lg md:flex-row md:max-w-xl hover:bg-white-100 dark:border-ehite-700 dark:bg-white-800 dark:hover:bg-white-700">
            <img className="object-cover w-full rounded-t-lg h-48 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src={record?.logo} alt="" />
            <div className="flex flex-col justify-between p-4 ml-5 leading-normal">
              <h2 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-black">{record?.name}</h2>
              <h3 className="mb-3 font-normal text-gray-700 dark:text-gray-900">Name: {record?.name}</h3>
              <h3 className="mb-3 font-normal text-gray-700 dark:text-gray-900">Type: {record?.type}</h3>
              <h3 className="mb-3 font-normal text-gray-700 dark:text-gray-900">Phone number: {record?.phoneNumber}</h3>
            </div>
          </a>

        </Row>
        <Row>
          <Col className="gutter-row ml-8 w-[6vw]" span={10}>
            {record && record.menuCategories && (
              <MenuCategories categories={record.menuCategories} place={record} activeTab={activeTab} />
            )}
          </Col>
          <Col>
            <Button onClick={() => {
              _.isEqual(record.menuCategories, []) ?
                Modal.warning({
                  title: 'Category to add new place to is not selected',
                  content: 'Please Select Category',
                })
                :
                setShowAddModal(true)
            }} type="primary">Add New Menu</Button></Col>
        </Row>
        <Divider />
        <Show isLoading={isMenuLoading || isFetching || isLoading}>
          <Tabs
            defaultActiveKey="1"
            tabPosition={'left'}
            className="ml-8"
            onChange={handleTabChange}
            style={{ height: '100%' }}
            items={record?.menuCategories?.map((item: IPlaceCategories) => {
              return {
                label: <span className="flex flex-col items-center"><img src={item.icon} alt={item.name} className="w-8 h-8" /> {item.name}</span>,
                key: item._id,
                children: renderMenus(item)
              };
            })}
          />
        </Show>


      </Show>
    </>
  );
};
