import { Button, Image, Input, Modal,  Space, Tooltip, Upload } from "antd"
import { ChangeEvent, useEffect, useState } from "react";
import { CheckOutlined, CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { apiUrl } from "../../constants";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";


export const MenuCategories = ({ categories, activeTab, place }: any) => {
  let activeCategory = categories[activeTab - 1] || categories.find((cat: any) => cat._id === activeTab);
  const [selectedCategory, setSelectedCategory] = useState<any>(activeCategory);
  const [onEdit, setOnEdit] = useState(false);
  const [onAdd, setOnAdd] = useState(false);
  const [editName, setEditName] = useState(activeCategory?.name || "");
  const [categoryName, setCategoryName] = useState("");
  const [categoryIcon, setCategoryIcon] = useState<any>("");
  const [showImageEditModal, setShowImageEditModal] = useState(false)
  const [image, setImage] = useState<any>();
  const [imageUrl, setImageUrl] = useState<any>();

  const handleRemove = () => {
    setImage(null);
    setShowImageEditModal(false)
  };
  useEffect(() => {
    if (activeCategory === undefined) {
      activeCategory = categories[0] || {}
    }
    console.log(activeCategory)

    setSelectedCategory(activeCategory)
  }, [activeCategory])


  const { mutate: editCategory, isLoading: isEditCategoryLoading } = useMutation((DATA: any) => {
    return axios.put(`${apiUrl}/place/${place._id}`, DATA,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      });
  });

  const onFinish = async (event: any) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("image", categoryIcon);
    const url = await uploadImage(formData);
    setImageUrl(url);
    handleAddCategory(imageUrl);
  };
  const onEditFinish = async (event: any) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("image", image);
    const url = await uploadImage(formData);
    setImageUrl(url);
  };

  const handleEdit = () => {
    let selected = selectedCategory;
    const oldMenuKey = selectedCategory.key;
    selected['key'] = editName.toLowerCase();
    selected['name'] = editName;
    const updated = categories.map((c: any) => c.key === selected?.key ? selected : c);
    console.log(selectedCategory.key, updated, editName.toLowerCase(), oldMenuKey)
    try {
      editCategory({
        icon: imageUrl,
        menuCategories: updated,
        oldMenuKey: oldMenuKey,
        newMenuKey: editName.toLowerCase()
      })
    } catch (error) {

    }

    setOnEdit(false);
  }


  const handleCancelEdit = () => {
    setOnEdit(false);
  }

  const handleCancelAdd = () => {
    setOnAdd(false);
  }

  const uploadImage = async (formData: FormData): Promise<string> => {
    const response = await fetch(`${apiUrl}/image-upload`, {
      method: 'POST',
      body: formData,
    });
    const data = await response.json();
    return data.imageUrl;
  };
  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement
    if (target.files) {
      setImage(target.files[0]);
    }
  }
  const { mutate: addCategory, isLoading: isMenuLoading } = useMutation((DATA: any) => {
    console.log("oooooooooooooooooooooooooooooooooooooooooooo")
    return axios.put(`${apiUrl}/place/create-category/${place._id}`, DATA);
  });
  const { mutate: deleteCategory, isLoading: isDeleteCategoryLoading } = useMutation(() => {
    return axios.delete(`${apiUrl}/place/delete-category/${place._id}/${selectedCategory._id}`);
  });

  const handleAddCategory = (imageUrl: string) => {
    const dataToAdd: any = { key: categoryName.toLowerCase(), name: categoryName, icon: imageUrl };
    console.log("🚀 ~ file: show.tsx:69 ~ handleAdd ~ tempArr:", JSON.stringify(dataToAdd))
    addCategory({
      menuCategories: dataToAdd,
    });
    setOnAdd(false);
  };

  const { confirm } = Modal;

  const camelCaseIt = (string: any) => string.toLowerCase().trim().split(/[.\-_\s]/g).reduce((string: any, word: any) => string + word[0].toUpperCase() + word.slice(1));
  {/** Delete category */ }
  const showConfirm = () => {
    !activeCategory ?
      Modal.warning({
        title: 'Category to delete not selected',
        content: 'Please Select Category',
      })
      :
      confirm({
        title: `Do you want to delete "${activeCategory.name}" category?`,
        content:
          `This actions is not reversible. Are you sure you want to delete "${activeCategory.name}" category?`,
        async onOk() {
          try {
            return new Promise((resolve, reject) => {
              deleteCategory();
              setTimeout(!isDeleteCategoryLoading ? resolve : reject, 100)
            });
          } catch (e) {
            console.log('Oops errors!');
          }
        },
        onCancel() { },
        okButtonProps: {
          type: 'primary',
          danger: true,
        },
      });
  };
  
  return (
    <>
      {onEdit ?
        <Space.Compact >
          <p style={{ width: '50%', paddingTop: 10 }}>Edit Selected Category</p>
          <Input value={editName} onChange={(e) => setEditName(e.target.value)} />
          <Button onClick={handleEdit} type="primary" shape="circle" icon={<CheckOutlined />} />
          <Button onClick={handleCancelEdit} type="primary" danger shape="circle" icon={<CloseOutlined />} />
          <Image
            style={{ marginLeft: 30 }}
            width={120}
            src={selectedCategory?.icon}
          />
          <Tooltip title="Change Menu Category Icon">
            <Button
              onClick={() => setShowImageEditModal(true)}
              style={{ marginLeft: 30, height: 50 }}
              icon={<EditOutlined />} />

          </Tooltip>
        </Space.Compact> :
        onAdd ?
          <Space.Compact >
            <p style={{ width: '50%', paddingTop: 10 }}>Add Category</p>
            <Input onChange={(e) => setCategoryName(e.target.value)} />
            <label htmlFor="icon" className=" w-1/4 ml-3 mr-3">Add Icon</label>
            <Input type="file" id="icon" onChange={(e) => {
              const target = e.target as HTMLInputElement
              if (target.files) {
                setCategoryIcon(target.files[0]);
              }
            }} />
            <Button
              onClick={onFinish}
              type="primary"
              shape="circle"
              icon={<CheckOutlined />}
            />            <Button onClick={handleCancelAdd} type="primary" danger shape="circle" icon={<CloseOutlined />} />
          </Space.Compact> :
          <>
            <Button onClick={() => setOnAdd(true)} type="ghost">Add Category</Button>
            <Button onClick={() => {
              !activeCategory ?
                Modal.warning({
                  title: 'Category to edit not selected',
                  content: 'Please Select Category',
                })
                :
                setOnEdit(true)
            }} style={{ marginLeft: 10 }} type="ghost">Edit Selected Category</Button>
            <Button style={{ marginLeft: 10 }} onClick={showConfirm} type="primary" danger>Delete Selected Category</Button>
          </>
      }
      <Modal
        open={showImageEditModal}
        title="Upload an Image"
        onCancel={handleRemove}
        onOk={() => onEditFinish}
      >
        <Upload
          name="image"
          listType="picture-card"
          onChange={() => handleImageChange}
          onRemove={handleRemove}
        >
          {image ? (
            <img src={image} alt="uploaded image" className="w-10" style={{ maxWidth: "100%" }} />
          ) : (
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          )}
        </Upload>
      </Modal>

    </>
  )
}