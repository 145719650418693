import { Button, Modal, Spin } from 'antd';
import React, { useState } from 'react';
import { apiUrl } from '../../constants';
import logo from "../../assets/img/logo.png";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import loginCover from "../../assets/img/login_cover.jpg";



export const Login: React.FC = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("")
  const [showModal, setShowModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Login logic
  const { mutate, isLoading } = useMutation<any>(
    (param) =>
      fetch(`${apiUrl}/auth/signin`, {
        method: "POST",
        body: JSON.stringify(param),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        credentials: 'include'
      }),
    {
      onSuccess: async (data) => {
        if (data?.status != 200)
        {
          setErrorMessage("Incorrect phone number or password");
        }else{
          const response = await data.json();
          handleLogin(response.user, data.token);
        }
      },
      onError: (error) => {
        setErrorMessage("Incorrect phone number or password");
      }
    }
  );

  // set the current logged in user in ghe local storage
  const handleLogin = async (data: any, token: any) => {
    console.log("🚀 ~ file: index.tsx:32 ~ handleLogin ~ data:", data)
    try {
        localStorage.setItem("token", token)
        localStorage.setItem("userdata", JSON.stringify(data));
        navigate("/");
    } catch (error) {
      setShowModal(true);
    }
  }

  // If we failed to save current logged in user in the local storage
  // this modal will appear and disappear after 3 sec, and redirect to login
  const handleOk = () => {
    setTimeout(() => {
      setShowModal(false);
      // redirect to the login page
      navigate('/login');
    }, 3000); // 3000 milliseconds = 3 seconds
  };
  const handleCancel = () => {
    setShowModal(false);
  };

  const handlePhoneChange = (event: any) => {
    event.preventDefault();
    setPhoneNumber(event.target.value)
    setErrorMessage("");
  }
  const handlePasswordChange = (event: any) => {
    event.preventDefault();
    setPassword(event.target.value)
    setErrorMessage("");
  }

  const onFinish = (event: any) => {
    event.preventDefault()
    if (!phoneNumber || !password){
      setErrorMessage("Please fill all the fields")
    }else{
    // @ts-ignore
    mutate({
      phoneNumber: phoneNumber,
      password: password
    })}
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900" style={{
      backgroundImage: `url(${loginCover})`,
      backgroundSize: "cover",
    }}>
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <Modal
        title="Login Error"
        open={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>There was an error with your login. Please try again.</p>
      </Modal>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <a href="#" className="flex ml-[40%] mt-1 mb-[-1.5rem] items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          <img className="w-24 h-24 mr-2" src={logo} alt="logo" />
        </a>
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Sign in
            </h1>
            {isLoading ? <Spin className='ml-[49%]'/> : ""}
            <form className="space-y-4 md:space-y-6">
            
        <div className="text-red-600">{errorMessage}</div>
      
              <div>
                <label htmlFor="phoneNumber" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Phone number</label>
                <input onChange={handlePhoneChange} type="text" name="phoneNumber" id="phoneNumber" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Your phone number here" required />
              </div>
              <div>
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                <input onChange={handlePasswordChange} type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="remember" className="text-gray-500 dark:text-gray-300">Remember me</label>
                  </div>
                </div>
                <a href="#" className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">Forgot password?</a>
              </div>

              <Button onClick={onFinish} type="primary" className='w-full dark:text-white'>Sign in</Button>
              <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                Don’t have an account yet? <a onClick={()=>navigate("/signup")} className="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign up</a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
