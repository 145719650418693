import { Table, Space, List, Button, Radio, RadioChangeEvent, Input, Modal } from "antd";
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from "@tanstack/react-query";
import { apiUrl } from "../../constants";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Show } from "../../components";
import Navbar from "../../components/Navbar/Navbar";
import { useEffect, useState } from "react";
import { IPlace } from "../../interfaces";
import TextArea from "antd/es/input/TextArea";


export const PlaceList: React.FC = () => {
    const navigate = useNavigate();

    // State variables
    const [newPlace, setNewPlace] = useState({});
    const [showAddModal, setShowAddModal] = useState(false);
    const [placesData, setplacesData] = useState<IPlace[]>();
    const [placeType, selectedPlaceType] = useState("all");
    const [logo, setLogo] = useState<any>("");
    const [banner, setBanner] = useState<any>("");
    const currentUserString = localStorage.getItem('userdata');
    const currentUser = currentUserString ? JSON.parse(currentUserString) : null;
    const places = ["all", "cafe", "hotel", "lounge"]


    // API calls
    const config = {
        headers: {
            "Content-Type": "application/json"
        },
        withCredentials: true
    }
    const fetchPlaces = async () => {
        const data = await axios.get(`${apiUrl}/all-places`, config)
        return data;
    };
    const { mutate: createPlace, isLoading: isPlaceLoading } = useMutation((DATA: any) => {
        return axios.post(`${apiUrl}/place/`, DATA);
    });
    const { mutate: deletePlace, isLoading: isDeletePlaceLoading } = useMutation((DATA: any) => {
        return axios.delete(`${apiUrl}/place/${DATA._id}`);
    });
    const { data, isLoading } = useQuery(['place'], fetchPlaces);

    // Change and submit handlers
    const handleAdd = (place: any) => {
        createPlace(place);
        setShowAddModal(false);
    }
    const handleEdit = (record: any) => {
        navigate("/place", { state: { id: record?._id } });
    }
    const onChangeRadio = (e: RadioChangeEvent) => {
        selectedPlaceType(e.target.value);
        if (e.target.value === "all") {
            setplacesData(data?.data)
        } else {
            console.log(e.target.value)
            setplacesData(data?.data.filter((place: IPlace) => place.type ? place.type.toLowerCase() === e.target.value.toLowerCase() : false));
        }
    };
    useEffect(() => {
        if (placeType === "all") {
            setplacesData(data?.data)
        } else {
            setplacesData(data?.data.filter((place: IPlace) => place.type ? place.type.toLowerCase() === placeType.toLowerCase() : false));
        }
    }, [data])

    const uploadImage = async (formData: FormData): Promise<string> => {
        const response = await fetch(`${apiUrl}/image-upload`, {
            method: 'POST',
            body: formData,
        });
        const data = await response.json();
        return data.imageUrl;
    };

    const onFinish = async (event: any) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("image", logo);
        const logoUrl: any = await uploadImage(formData);
        const formData_banner = new FormData();
        formData_banner.append("image", banner);
        const bannerUrl: any = await uploadImage(formData_banner);
        handleAdd({ ...newPlace, "logo": logoUrl, banner: bannerUrl });
    };

    const style: React.CSSProperties = { padding: '8px 0', display: 'flex' };
    const { confirm } = Modal;
    {/** Delete place Modal */ }
    const showConfirm = (place: any) => {
        confirm({
            title: `Do you want to delete "${place.name}"?`,
            content:
                `This actions is not reversible. Are you sure you want to delete "${place.name}"?`,
            async onOk() {
                try {
                    return new Promise((resolve, reject) => {
                        deletePlace(place);
                        setTimeout(!isDeletePlaceLoading ? resolve : reject, 100)
                    });
                } catch (e) {
                    console.log('Oops errors!');
                }
            },
            onCancel() { },
            okButtonProps: {
                type: 'primary',
                danger: true,
            },
        });
    };

    return (
        <>
            <Navbar onOpenSidenav={function (): void {
                throw new Error("Function not implemented.");
            }} brandText={""} />
            <a href="/dashboard" className="fixed top-35 left-[-1.5rem] z-10 bg-gray-200 hover:bg-gray-300 text-gray-700 font-semibold py-1 px-4 border border-gray-400 rounded shadow transform rotate-90">
                Home
            </a>
            <div style={style} className="flex justify-between items-center">
                <div className="flex ml-8">
                    <p className="mr-2 ml-4">Filter by:</p>
                    <Radio.Group
                        onChange={onChangeRadio}
                        value={placeType}
                        optionType="button"
                        buttonStyle="solid"
                    >
                        {places?.map((item: string) => (
                            <Radio key={item} value={item}>
                                {item}
                            </Radio>
                        ))}
                    </Radio.Group>
                </div>

                {currentUser.role === "Admin" ? <Button
                    onClick={() => {
                        setShowAddModal(true)
                    }}
                    type="primary"
                    className="mr-4"
                >
                    Add New Place
                </Button> : <></>
                }
            </div>

            <List className="mt-2">
                <Show isLoading={isLoading} >
                    <Table dataSource={placesData} rowKey="_id" className="ml-10 w-[97vw]">
                        <Table.Column dataIndex="name" title="Name" />
                        <Table.Column dataIndex="phoneNumber" title="Phone Number" />
                        <Table.Column
                            title="Actions"
                            dataIndex="actions"
                            render={(_, record) => (
                                <Space>
                                    <Button
                                        type="text"
                                        icon={<EditOutlined />}
                                        onClick={() => { handleEdit(record) }}
                                    />
                                    <Button danger
                                        type="text"
                                        icon={<DeleteOutlined />}
                                        onClick={() => showConfirm(record)}
                                    />
                                </Space>

                            )}
                        />
                    </Table>
                </Show>
            </List>

            {/* add new place */}
            <Modal
                title="Add Place"
                open={showAddModal}
                onOk={onFinish}
                onCancel={() => setShowAddModal(false)}
            >
                <div className="flex flex-row pb-15 pt-5">
                    <p className="p-5">Type</p>
                    <Input
                        className="w-full"
                        onChange={(e) => {
                            setNewPlace({ ...newPlace, type: e.target.value });
                        }}
                        placeholder="The name of the place"
                    />
                </div>
                <div className="flex flex-row pb-15 pt-5">
                    <p className="p-5">Name</p>
                    <Input
                        className="w-full"
                        onChange={(e) => {
                            setNewPlace({ ...newPlace, name: e.target.value });
                        }}
                        placeholder="The name of the place"
                    />
                </div>
                <div className="flex flex-row pb-15 pt-5">
                    <p className="p-5">Phone</p>
                    <Input
                        className="w-full"
                        onChange={(e) => {
                            setNewPlace({ ...newPlace, phoneNumber: e.target.value });
                        }}
                        placeholder="Your main phone number"
                    />
                </div>
                <div className="flex flex-row pb-15 pt-5">
                    <p className="p-5">QR</p>
                    <Input
                        className="w-full"
                        onChange={(e) => {
                            setNewPlace({ ...newPlace, qr: e.target.value });
                        }}
                        placeholder="Basic usage"
                    />
                </div>
                <div className="flex flex-row pb-15 pt-5">
                    <p className="p-5">Logo</p>
                    <Input
                        className="w-full"
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                            const target = e.target as HTMLInputElement
                            if (target.files) {
                                setLogo(target.files[0]);
                            }
                        }}
                    />
                </div>
                <div className="flex flex-row pb-15 pt-5">
                    <p className="p-5">Banner</p>
                    <Input
                        className="w-full"
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                            const target = e.target as HTMLInputElement
                            if (target.files) {
                                setBanner(target.files[0]);
                            }
                        }}
                    />
                </div>
                <div className="flex">
                    <div className="flex flex-row pb-15 pt-5 w-1/2">
                        <p className="p-5">Longitude</p>
                        <Input
                            className="w-full"
                            onChange={(e) => {
                                setNewPlace({ ...newPlace, longitude: e.target.value });
                            }}
                            placeholder="Enter latitude and longitude (separated by comma)"
                        />
                    </div>
                    <div className="flex flex-row pb-15 pt-5 w-1/2">
                        <p className="p-5">Latitude</p>
                        <Input
                            className="w-full"
                            onChange={(e) => {
                                setNewPlace({ ...newPlace, latitude: e.target.value });
                            }}
                            placeholder="Enter latitude and longitude (separated by comma)"
                        />
                    </div>
                </div>
                <p className="pt-5">Place Description</p>
                <TextArea
                    className="w-full"
                    onChange={(e) => {
                        setNewPlace({ ...newPlace, about: e.target.value });
                    }}
                    rows={4}
                    placeholder="maxLength is 50"
                    maxLength={50}
                />
            </Modal>


        </>

    );
};
