import React, { useEffect, useState } from "react";
import { FiAlignJustify } from "react-icons/fi";
import { FiSearch, FiHome } from "react-icons/fi";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import Logo from "../../assets/img/logo.png";
import avatar from "../../assets/img/avatars/avatar4.png";
import Dropdown from "../dropdown";
import { useNavigate } from "react-router-dom";


const Navbar = (props: {
  onOpenSidenav: () => void;
  brandText: string;
  secondary?: boolean | string;
}) => {
  const { onOpenSidenav, brandText } = props;
  const [darkmode, setDarkmode] = React.useState(false);
  const currentUserString = localStorage.getItem('userdata');
  const currentUser = currentUserString ? JSON.parse(currentUserString) : null;
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const handleLogout = async () => {
    localStorage.removeItem('userdata');
    navigate('/login');
  };
  return (
<nav className={`sticky w-full p-1 mb-8 top-0 z-40 flex flex-row flex-wrap items-center justify-between bg-white/10 ${isScrolled ? 'backdrop-blur-xl shadow-md' : ''} dark:bg-white`} style={{ borderRadius: '10px' }}>
               <img
        className="h-16 w-16 ml-4 rounded-full"
        src={Logo}
        alt={currentUser? currentUser.image ?? currentUser.image :"logo"}
      />
      <div className="relative mt-[3px] flex h-[61px] w-1/2 flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[395px] md:flex-grow-0 md:gap-1 xl:w-[395px] xl:gap-2">
        <div className="flex h-full w-[2vw] items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[355px] border border-solid border-[#93cc4e] border-[0.15rem]">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-4 w text-black-400 dark:text-black" />
          </p>
          <input
            type="text"
            placeholder="Search..."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-black-400 dark:bg-navy-900 dark:text-black dark:placeholder:!text-black sm:w-fit"
          />
        </div>
        <span
          className="flex cursor-pointer text-xl text-black-600 dark:text-black xl:hidden"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>
        {/* start Notification */}

        {/* start Horizon PRO */}
        <div
          className="cursor-pointer text-black-600"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove("dark");
              setDarkmode(false);
            } else {
              document.body.classList.add("dark");
              setDarkmode(true);
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className="h-4 w-4 text-black-600 dark:text-black" />
          ) : (
            <RiMoonFill className="h-4 w-4 text-black-600 dark:text-black" />
          )}
        </div>
        {/* Profile & Dropdown */}
        <Dropdown
          button={
            <img
              className="h-10 w-10 rounded-full"
              src={currentUser.image || "https://olla-bucket.s3.us-west-004.backblazeb2.com/user.png"}
              alt={currentUser.name}
            />
          }
          children={
            <div className="flex h-48 w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="mt-3 ml-4">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-black">
                    👋 Hey, {currentUser.username}
                  </p>{" "}
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-black-200 dark:bg-black/20 " />

              <div className="mt-3 ml-4 flex flex-col">
                <a
                  href=" "
                  className="text-sm text-black-800 dark:text-black hover:dark:text-black"
                >
                  Profile Settings
                </a>
                <a
                  href=" "
                  onClick={handleLogout}
                  className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                >
                  Log Out
                </a>
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>
    </nav>


  );
};

export default Navbar;
