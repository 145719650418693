import { UserOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Widget from "../../components/Widget/Widget";


export const Dashboard: React.FC = () => {
    const navigate = useNavigate();

   
    const currentUserString = localStorage.getItem('userdata');
    const currentUser = currentUserString ? JSON.parse(currentUserString) : null;
    const style: React.CSSProperties = { padding: '8px 0', display: 'flex' };
   
    return (
        <>
            <Navbar onOpenSidenav={function (): void {
                throw new Error("Function not implemented.");
            }} brandText={""} />

            <div style={style} className="flex w-1/2 justify-between items-center">
            {currentUser.role === "Admin" ?<Link to="#"><Widget icon={<UserOutlined />} title={"Users"} subtitle={"You'll find all users registered to the system here."} ></Widget></Link>:<></> }
                <Link to="/placeList"><Widget icon={<EnvironmentOutlined />} title={"Places"} subtitle={"You'll find all Places registered to the system here."}></Widget></Link>
            </div>
        </>

    );
};
